<template>
  <div
    class="sf-product-card"
    :class="{ 'has-colors': colors.length }"
    data-testid="product-card"
  >
    <div class="sf-product-card__image-wrapper">
      <SfButton
        :link="link"
        class="sf-button--pure sf-product-card__link"
        data-testid="product-link"
        aria-label="Go To Product"
        v-on="$listeners"
      >
        <template v-if="Array.isArray(image)">
          <SfImage
            v-for="(picture, key) in image.slice(0, 2)"
            :key="key"
            class="sf-product-card__picture"
            :src="picture"
            :alt="imageAlt"
            :width="imageWidth"
            :height="imageHeight"
            :placeholder="productPlaceholder"
            :loading="isBigImage ? 'eager' : 'lazy'"
            :max-placeholder-wait="isBigImage && cardIndex === 2 ? 0 : 2500"
            :is-big-image="isBigImage"
            :card-index="cardIndex"
          />
        </template>
        <SfImage
          v-else
          class="sf-product-card__image"
          :src="image"
          :alt="imageAlt"
          :width="imageWidth"
          :height="imageHeight"
          :placeholder="productPlaceholder"
          :loading="isBigImage ? 'eager' : 'lazy'"
          :max-placeholder-wait="isBigImage && cardIndex === 2 ? 0 : 2500"
          :is-big-image="isBigImage"
          :card-index="cardIndex"
        />
      </SfButton>
      <slot
        name="colors"
        v-bind="{ colors }"
      >
        <SfColorPicker
          :class="{ 'display-none': colors.length === 0 }"
          class="sf-product-card__colors"
          label="Choose color"
          :is-open="!isMobile || openColorPicker"
          @click:toggle="toggleColorPicker"
        >
          <SfColor
            v-for="(color, i) in colors"
            :key="color.value"
            :color="color.color"
            :selected="color.selected"
            class="sf-product-card__color"
            :class="{ 'display-none': i > 3 && showBadge }"
            @click="handleSelectedColor(i)"
          />
          <SfBadge
            v-if="showBadge"
            class="sf-product-card__colors-badge color-secondary"
          >
            {{ `+${colors.length - 4}` }}
          </SfBadge>
        </SfColorPicker>
      </slot>
      <slot
        name="badge"
        v-bind="{ badgeLabel, badgeColor }"
      >
        <SfBadge
          class="sf-product-card__badge"
          :class="[badgeColorClass, { 'display-none': !badgeLabel }]"
        >
          {{ badgeLabel }}
        </SfBadge>
      </slot>
      <SfButton
        :aria-label="`${ariaLabel} ${title}`"
        :class="[wishlistIconClasses, { 'display-none': !wishlistIcon }]"
        data-testid="product-wishlist-button"
        @click="toggleIsInWishlist"
      >
        <slot
          name="wishlist-icon"
          v-bind="{ currentWishlistIcon }"
        >
          <SfIcon
            v-if="currentWishlistIcon === 'heart_fill'"
            icon="heart_fill"
            size="lg"
            data-test="sf-wishlist-icon"
          />
          <SfIcon
            v-else
            class="wishlist-empty-icon"
          >
            <nuxt-img
              width="28"
              height="28"
              src="/icons/wishlist-heart.svg"
              alt="empty wishlist icon"
            />
          </SfIcon>
        </slot>
      </SfButton>
      <!-- eslint-disable vue/no-lone-template -->
      <template :class="{ 'display-none': !showAddToCartButton }">
        <slot
          name="add-to-cart"
          v-bind="{
            isAddedToCart,
            showAddedToCartBadge,
            isAddingToCart,
            title,
          }"
        >
          <SfCircleIcon
            class="sf-product-card__add-button"
            :aria-label="`Add to Cart ${title}`"
            :has-badge="showAddedToCartBadge"
            :disabled="addToCartDisabled"
            data-testid="product-add-icon"
            @click="onAddToCart"
          >
            <span class="sf-product-card__add-button--icons">
              <transition
                v-if="!isAddingToCart && !isAddedToCart"
                name="sf-pulse"
                mode="out-in"
              >
                <slot name="add-to-cart-icon">
                  <SfIcon
                    key="add_to_cart"
                    icon="add_to_cart"
                    size="20px"
                    color="white"
                  />
                </slot>
              </transition>
              <transition
                v-else
                name="sf-pulse"
                mode="out-in"
              >
                <slot name="adding-to-cart-icon">
                  <SfIcon
                    key="added_to_cart"
                    icon="added_to_cart"
                    size="20px"
                    color="white"
                  />
                </slot>
              </transition>
            </span>
          </SfCircleIcon>
        </slot>
      </template>
    </div>
    <slot
      name="title"
      v-bind="{ title, link }"
    >
      <SfButton
        :link="link"
        class="sf-button--pure sf-product-card__link"
        data-testid="product-link"
        v-on="$listeners"
      >
        <span class="sf-product-card__title">
          {{ title }}
        </span>
      </SfButton>
    </slot>
    <slot
      name="price"
      v-bind="{ specialPrice, regularPrice }"
    >
      <SfPrice
        :class="{ 'display-none': !regularPrice }"
        class="sf-product-card__price"
        :regular="regularPrice"
        :special="specialPrice"
      />
    </slot>
    <slot
      name="omnibus-price"
      v-bind="{omnibusPrice}"
    >
      <span
        v-if="omnibusPrice || specialPrice"
        class="sf-product-card__omnibus-price"
      >
        {{ $t('The price was {price}', {price: omnibusPrice ? omnibusPrice : regularPrice}) }}
      </span>
    </slot>
    <slot
      name="reviews"
      v-bind="{ maxRating, scoreRating }"
    >
      <div
        :class="{ 'display-none': !scoreRating }"
        class="sf-product-card__reviews"
      >
        <SfRating
          v-if="typeof scoreRating === 'number'"
          class="sf-product-card__rating"
          :max="maxRating"
          :score="scoreRating"
        />
        <SfButton
          :class="{ 'display-none': !reviewsCount }"
          :aria-label="`Read reviews about ${title}`"
          class="sf-button--pure sf-product-card__reviews-count"
          data-testid="product-review-button"
          @click="$emit('click:reviews')"
        >
          ({{ reviewsCount }})
        </SfButton>
      </div>
    </slot>
    <slot
      name="discount-percentage"
    >
      <div
        class="sf-product-card__discount-percentage"
        :class="{'display-none': !specialPrice || !calcDiscountPercentage }"
      >
        {{ '-'+calcDiscountPercentage+'%' }}
      </div>
    </slot>
  </div>
</template>
<script>
import { colorsValues as SF_COLORS } from '@storefront-ui/shared/variables/colors';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import SfPrice from '@storefront-ui/vue/src/components/atoms/SfPrice/SfPrice.vue';
import SfRating from '@storefront-ui/vue/src/components/atoms/SfRating/SfRating.vue';
import SfCircleIcon from '@storefront-ui/vue/src/components/atoms/SfCircleIcon/SfCircleIcon.vue';
import SfBadge from '@storefront-ui/vue/src/components/atoms/SfBadge/SfBadge.vue';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfColorPicker from '@storefront-ui/vue/src/components/molecules/SfColorPicker/SfColorPicker.vue';
import SfColor from '@storefront-ui/vue/src/components/atoms/SfColor/SfColor.vue';
import {
  mapMobileObserver,
  unMapMobileObserver,
} from '@storefront-ui/vue/src/utilities/mobile-observer';
import productPlaceholder from '~/assets/images/product_placeholder.svg';
import SfImage from './SfImage';

export default {
  name: 'SfProductCardCustom',
  components: {
    SfPrice,
    SfRating,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfBadge,
    SfButton,
    SfColorPicker,
    SfColor,
  },
  props: {
    image: {
      type: [Array, Object, String],
      default: '',
    },
    smallImage: {
      type: String,
      default: '',
    },
    imageWidth: {
      type: [String, Number],
      default: '100%',
    },
    imageHeight: {
      type: [String, Number],
      default: 'auto',
    },
    imageAlt: {
      type: String,
      default: '',
    },
    imageLoading: {
      type: [String],
      default: 'lazy',
    },
    badgeLabel: {
      type: String,
      default: '',
    },
    badgeColor: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    omnibusPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart',
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: 'heart_fill',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      deafult: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    showWishlistButton: {
      type: Boolean,
      default: true,
    },
    isBigImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      isAddingToCart: false,
      openColorPicker: false,
      productPlaceholder,
    };
  },
  computed: {
    ...mapMobileObserver(),
    isSFColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : '';
    },
    currentWishlistIcon() {
      return this.isInWishlist ? this.isInWishlistIcon : this.wishlistIcon;
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    ariaLabel() {
      return this.isInWishlist ? 'Remove from wishlist' : 'Add to wishlist';
    },
    wishlistIconClasses() {
      const defaultClass = 'sf-button--pure sf-product-card__wishlist-icon';
      return `${defaultClass} ${this.isInWishlist ? 'on-wishlist' : ''}`;
    },
    showBadge() {
      return this.colors.length > 5;
    },
    calcDiscountPercentage() {
      const currencyCheckerRegex = /[^\d,.]/g;
      const parsedSpecialPrice = this.specialPrice ? this.specialPrice.replace(currencyCheckerRegex, '') : null;
      const parsedRegularPrice = this.regularPrice ? this.regularPrice.replace(currencyCheckerRegex, '') : null;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const discountPercentage = Math.trunc([1 - (Number.parseFloat(parsedSpecialPrice) / Number.parseFloat(parsedRegularPrice))] * 100);
      const roundedDiscountPercentage = discountPercentage ? Math.round(discountPercentage / 10) * 10 : 0;
      return parsedSpecialPrice ? roundedDiscountPercentage : null;
    },
  },
  beforeDestroy() {
    unMapMobileObserver();
  },
  methods: {
    toggleIsInWishlist() {
      this.$emit('click:wishlist', !this.isInWishlist);
    },
    onAddToCart(event) {
      event.preventDefault();
      this.isAddingToCart = true;
      setTimeout(() => {
        this.isAddingToCart = false;
      }, 1000);
      this.$emit('click:add-to-cart');
    },
    handleSelectedColor(colorIndex) {
      if (this.colors.length > 0) {
        this.colors.forEach((color, i) => {
          if (colorIndex === i) {
            this.$emit('click:colors', color);
            if (this.isMobile) {
              this.toggleColorPicker();
            }
          }
        });
      }
    },
    toggleColorPicker() {
      this.openColorPicker = !this.openColorPicker;
    },
  },
};
</script>
<style lang="scss">
@import '~@storefront-ui/shared/styles/components/organisms/SfProductCard.scss';
.yf-custom-product-card .sf-image--wrapper {
  .sf-image--placeholder {
    padding: 8vh;
  }
}
</style>
